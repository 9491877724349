import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const AboutPage = () => {
  return (
    <Layout>
      <h1>About Me</h1>
      <Link to="/">Back to Home</Link>
      <p>
        Hi there! I'm the proud creator of this site, which I built with Gatsby.
      </p>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="About Me"
    description="A passionate javascript developer with 6+ years experiences focusing on building user-friendly web applications. Currently dedicate myself in grasping the holistic architect of web development with a goal to build resilient and performant web products."
    author="Beck"
  />
)

export default AboutPage
